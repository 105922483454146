<template>
  <div class="create-content-main">
    <div class="create-slag-form background-content-admin">
      <div class="row cpx-4">
        <div class="col col-auto">
          <div class="w-100 font-weight-bold header-font" v-if="!isEdit">
            URL登録
          </div>
          <div class="w-100 font-weight-bold header-font" v-else>URL 編集</div>
        </div>
        <div class="col col-auto">
          <div class="mx-3">
            <label
              v-for="(item, index) in listSetting"
              :key="index"
              class="mr-3 position-relative py-2"
              :class="
                settingSelect === item.value
                  ? 'btn-tab-sale-active'
                  : 'btn-tab-sale-inactive'
              "
              @click="settingSelect = item.value"
              style="font-weight: normal !important; font-size: 17px"
            >
              {{ item.text }}
            </label>
          </div>
        </div>
      </div>
      <form id="create-content" autocomplete="off">
        <div v-if="settingSelect === 1">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >URLを設計する（英数字を入力してオリジナルURLを作成）<span
                class="required"
                >(必須)</span
              ></label
            >
            <div class="w-100 cp-4 d-flex">
              <div
                class="mb-0 font-weight-bold mt-2 mr-1 ml-2"
                style="color: black"
              >
                <label>{{ baseUrl }}</label>
              </div>
              <div class="w-100">
                <input
                  class="form-input m-0"
                  type="text"
                  v-model="settingUrl.short_url"
                  v-click-outside="checkShortUrl"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="settingSelect === 2">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >リダイレクトさせたいURL先</label
            >
            <div class="w-100 cp-4">
              <input
                class="form-input m-0"
                type="text"
                v-model="settingUrl.st_url_redirect.url_no_condtion"
              />
            </div>
          </div>
          <label class="col-sm-12 font-weight-bold cp-label"
            >条件リダイレクト設定</label
          >
          <div class="w-50">
            <div
              class="row row-input"
              v-for="(item, index) in settingUrl.st_url_redirect
                .st_has_condtion"
              v-bind:key="index"
            >
              <div class="w-100 cp-4 d-flex">
                <div class="w-100">
                  <input
                    class="w-100 form-input m-0"
                    type="text"
                    v-model="item.url_has_condition"
                  />
                  <div class="d-flex justify-content-center mt-3">
                    <b-button
                      variant="success"
                      class="rounded-circle custome-btn-add cus-bt-color"
                      style="border-radius: 50% !important"
                      v-on:click="
                        addSetting(
                          settingUrl.st_url_redirect.st_has_condtion,
                          index,
                          'url'
                        )
                      "
                      ><b-icon class="sidebar-icon" icon="plus" font-scale="2"
                    /></b-button>
                  </div>
                </div>
                <b-icon
                  icon="gear-fill"
                  class="my-auto ml-2 bt-setting"
                  font-scale="2"
                  style="border-color: #ced2d8; padding: 4px"
                  v-on:click="setCondition(item.conditions, index)"
                />
                <b-button
                  v-if="settingUrl.st_url_redirect.st_has_condtion.length > 1"
                  variant="dark"
                  class="rounded-circle custome-btn-sub float-right mt-0 bt-sub"
                  style="border-radius: 50% !important"
                  v-on:click="
                    subSetting(
                      settingUrl.st_url_redirect.st_has_condtion,
                      index
                    )
                  "
                  ><b-icon icon="dash" scale="2"
                /></b-button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="settingSelect === 3">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >アクセス可能期間を設定する</label
            >
            <div
              class="fd-admin-listproperty-card-filter filter-date d-flex cp-4"
            >
              <date-picker
                name="date"
                v-model="settingUrl.st_redirect_by_time.time_start"
                :config="optionsDate"
                autocomplete="off"
                class="custome-text-select"
              ></date-picker>
              <span class="my-auto" style="font-size: 23px">~</span>
              <date-picker
                name="date"
                v-model="settingUrl.st_redirect_by_time.time_end"
                :config="optionsDate"
                autocomplete="off"
                class="custome-text-select"
              ></date-picker>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">
              アクセス可能期限が過ぎた時のリダイレクト先
            </label>
            <input
              class="form-input w-50"
              type="text"
              v-model="settingUrl.st_redirect_by_time.url_no_condtion"
            />
          </div>
          <label class="col-sm-12 font-weight-bold cp-label"
            >アクセス可能期限が過ぎた時のリダイレクト先（条件設定）</label
          >
          <div class="w-50">
            <div
              class="row row-input"
              v-for="(item, index) in settingUrl.st_redirect_by_time
                .st_has_condtion"
              v-bind:key="index"
            >
              <div class="w-100 cp-4 d-flex">
                <div class="w-100">
                  <input
                    class="w-100 form-input m-0"
                    type="text"
                    v-model="item.url_has_condition"
                  />
                  <div class="d-flex justify-content-center mt-3">
                    <b-button
                      variant="success"
                      class="rounded-circle custome-btn-add cus-bt-color"
                      style="border-radius: 50% !important"
                      v-on:click="
                        addSetting(
                          settingUrl.st_redirect_by_time.st_has_condtion,
                          index,
                          'url'
                        )
                      "
                      ><b-icon class="sidebar-icon" icon="plus" font-scale="2"
                    /></b-button>
                  </div>
                </div>
                <b-icon
                  icon="gear-fill"
                  class="my-auto ml-2 bt-setting"
                  font-scale="2"
                  style="border-color: #ced2d8; padding: 4px"
                  v-on:click="setCondition(item.conditions, index)"
                />
                <b-button
                  v-if="
                    settingUrl.st_redirect_by_time.st_has_condtion.length > 1
                  "
                  variant="dark"
                  class="rounded-circle custome-btn-sub float-right mt-0 bt-sub"
                  style="border-radius: 50% !important"
                  v-on:click="
                    subSetting(
                      settingUrl.st_redirect_by_time.st_has_condtion,
                      index
                    )
                  "
                  ><b-icon icon="dash" scale="2"
                /></b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-input" v-if="settingSelect === 4">
          <label class="col-sm-12 font-weight-bold cp-label">
            アクセス後のリダイレクト前に行う処理
          </label>
          <div class="col col-12">
            <div class="mx-3">
              <label
                v-for="(item, index) in listProcess"
                :key="index"
                class="mr-3 position-relative py-2"
                :class="
                  processSelect === item.value
                    ? 'btn-select-active'
                    : 'btn-select-inactive'
                "
                @click="changProcess(item.value)"
                style="font-weight: normal !important; font-size: 17px"
              >
                {{ item.text }}
              </label>
            </div>
          </div>
          <div class="col col-12 mt-4" v-if="processSelect != 1">
            <div class="mx-3">
              <label
                v-for="(item, index) in listTypeGive"
                :key="index"
                class="mr-3 position-relative py-2"
                :class="
                  typeGiveSelect === item.value
                    ? 'btn-tab-sale-active'
                    : 'btn-tab-sale-inactive'
                "
                @click="typeGiveSelect = item.value"
                style="
                  font-weight: normal !important;
                  font-size: 17px;
                  width: auto;
                "
              >
                {{ item.text }}
              </label>
            </div>
          </div>
        </div>
        <div
          v-if="settingSelect == 4 && typeGiveSelect == 1 && processSelect != 1"
        >
          <div class="row row-input mt-2">
            <label class="col-sm-12 font-weight-bold cp-label">
              ログイン後に付与する情報
            </label>
            <div class="w-100 cp-4 d-flex">
              <label class="font-weight-bold cus-label-c">
                付与コンテンツ :
              </label>
              <div class="custom-select-2 w-50 custome-multi-slag m-0">
                <multiselect
                  v-model="
                    settingUrl.st_before_redirect.st_give[typeGiveSelect - 1]
                      .content_no_condition
                  "
                  tag-placeholder="コンテンツを検索"
                  placeholder="コンテンツを検索"
                  label="text"
                  track-by="value"
                  :options="listContentOptions"
                  :multiple="true"
                  :taggable="false"
                  :showNoResults="false"
                  select-label="入力して選択する"
                  deselect-label="入力して削除する"
                  selected-label="選択された"
                >
                  <template v-slot:no-options> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input mt-2">
            <label class="col-sm-12 font-weight-bold cp-label">
              ログイン後、条件的に情報を付与する(コンテンツ)
            </label>
            <div
              class="col-sm-12 p-0"
              v-for="(item, index) in settingUrl.st_before_redirect.st_give[0]
                .st_has_condtion"
              v-bind:key="index"
            >
              <div class="w-100 cp-4 d-flex">
                <label class="font-weight-bold cus-label-c">
                  付与コンテンツ :
                </label>
                <div class="custom-select-2 w-50 custome-multi-slag m-0">
                  <multiselect
                    v-model="item.content_has_condition"
                    tag-placeholder="コンテンツを検索"
                    placeholder="コンテンツを検索"
                    label="text"
                    track-by="value"
                    :options="listContentOptions"
                    :multiple="true"
                    :taggable="false"
                    :showNoResults="false"
                    select-label="入力して選択する"
                    deselect-label="入力して削除する"
                    selected-label="選択された"
                  >
                    <template v-slot:no-options> リストは空です </template>
                  </multiselect>
                </div>
                <b-icon
                  icon="gear-fill"
                  class="my-auto ml-2 bt-setting"
                  font-scale="2"
                  style="border-color: #ced2d8; padding: 4px"
                  v-on:click="setCondition(item.conditions, index)"
                />
                <b-button
                  v-if="
                    settingUrl.st_before_redirect.st_give[typeGiveSelect - 1]
                      .st_has_condtion.length > 1
                  "
                  variant="dark"
                  class="rounded-circle custome-btn-sub float-right mt-0 bt-sub"
                  style="border-radius: 50% !important"
                  v-on:click="
                    subSetting(
                      settingUrl.st_before_redirect.st_give[typeGiveSelect - 1]
                        .st_has_condtion,
                      index
                    )
                  "
                  ><b-icon icon="dash" scale="2"
                /></b-button>
              </div>
              <div class="d-flex col-12 ml-5">
                <b-button
                  variant="success"
                  class="rounded-circle custome-btn-add cus-bt-color"
                  style="border-radius: 50% !important"
                  v-on:click="
                    addSetting(
                      settingUrl.st_before_redirect.st_give[typeGiveSelect - 1]
                        .st_has_condtion,
                      index,
                      'content'
                    )
                  "
                  ><b-icon class="sidebar-icon" icon="plus" font-scale="2"
                /></b-button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="settingSelect == 4 && typeGiveSelect == 2 && processSelect != 1"
        >
          <div class="row row-input mt-2">
            <label class="col-sm-12 font-weight-bold cp-label">
              ログイン後に付与する情報
            </label>
            <div class="w-100 cp-4 d-flex">
              <label class="font-weight-bold cus-label-c">
                付与コンテンツ :
              </label>
              <div class="custom-select-2 w-50 custome-multi-slag m-0">
                <multiselect
                  v-model="
                    settingUrl.st_before_redirect.st_give[typeGiveSelect - 1]
                      .slag_no_condition
                  "
                  tag-placeholder="スラグ追加"
                  placeholder="スラグ追加"
                  label="text"
                  track-by="value"
                  :options="listSlagOptions"
                  :multiple="true"
                  :taggable="false"
                  :showNoResults="false"
                  :select-label="'入力して選択する'"
                  :deselect-label="'入力して削除する'"
                  :selected-label="'選択された'"
                >
                  <template v-slot:no-options> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input mt-2">
            <label class="col-sm-12 font-weight-bold cp-label">
              ログイン後、条件的に情報を付与する(スラグ)
            </label>
            <div
              class="col-sm-12 p-0"
              v-for="(item, index) in settingUrl.st_before_redirect.st_give[
                typeGiveSelect - 1
              ].st_has_condtion"
              v-bind:key="index"
            >
              <div class="w-100 cp-4 d-flex">
                <label class="font-weight-bold cus-label-c">
                  付与コンテンツ :
                </label>
                <div class="custom-select-2 w-50 custome-multi-slag m-0">
                  <multiselect
                    v-model="item.slag_has_condition"
                    tag-placeholder="スラグ追加"
                    placeholder="スラグ追加"
                    label="text"
                    track-by="value"
                    :options="listSlagOptions"
                    :multiple="true"
                    :taggable="false"
                    :showNoResults="false"
                    :select-label="'入力して選択する'"
                    :deselect-label="'入力して削除する'"
                    :selected-label="'選択された'"
                  >
                    <template v-slot:no-options> リストは空です </template>
                  </multiselect>
                </div>
                <b-icon
                  icon="gear-fill"
                  class="my-auto ml-2 bt-setting"
                  font-scale="2"
                  style="border-color: #ced2d8; padding: 4px"
                  v-on:click="setCondition(item.conditions, index)"
                />
                <b-button
                  v-if="
                    settingUrl.st_before_redirect.st_give[typeGiveSelect - 1]
                      .st_has_condtion.length > 1
                  "
                  variant="dark"
                  class="rounded-circle custome-btn-sub float-right mt-0 bt-sub"
                  style="border-radius: 50% !important"
                  v-on:click="
                    subSetting(
                      settingUrl.st_before_redirect.st_give[typeGiveSelect - 1]
                        .st_has_condtion,
                      index
                    )
                  "
                  ><b-icon icon="dash" scale="2"
                /></b-button>
              </div>
              <div class="d-flex col-12 ml-5">
                <b-button
                  variant="success"
                  class="rounded-circle custome-btn-add cus-bt-color"
                  style="border-radius: 50% !important"
                  v-on:click="
                    addSetting(
                      settingUrl.st_before_redirect.st_give[typeGiveSelect - 1]
                        .st_has_condtion,
                      index,
                      'slag'
                    )
                  "
                  ><b-icon class="sidebar-icon" icon="plus" font-scale="2"
                /></b-button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="settingSelect == 4 && typeGiveSelect == 3 && processSelect != 1"
        >
          <div class="row row-input mt-2">
            <label class="col-sm-12 font-weight-bold cp-label">
              ログイン後に付与する情報
            </label>
            <div class="w-100 cp-4 d-flex">
              <label class="font-weight-bold cus-label-p"> ポイント : </label>
              <div class="custom-select-2 mr-2 custome-multi-slag m-0">
                <input
                  class="form-input m-0"
                  type="text"
                  @keypress="validate()"
                  @paste="validate()"
                  v-model="
                    settingUrl.st_before_redirect.st_give[typeGiveSelect - 1]
                      .point_no_condition
                  "
                  v-click-outside="checkShortUrl"
                />
              </div>
              <label class="font-weight-bold cus-label-p2"> ポイント </label>
            </div>
          </div>
          <div class="row row-input mt-2">
            <label class="col-sm-12 font-weight-bold cp-label">
              ログイン後、条件的に情報を付与する(ポイント)
            </label>
            <div
              class="col-sm-12 p-0"
              v-for="(item, index) in settingUrl.st_before_redirect.st_give[
                typeGiveSelect - 1
              ].st_has_condtion"
              v-bind:key="index"
            >
              <div class="w-100 cp-4 d-flex">
                <label class="font-weight-bold cus-label-p"> ポイント : </label>
                <div class="custom-select-2 mr-2 custome-multi-slag m-0">
                  <input
                    class="form-input m-0"
                    type="text"
                    @keypress="validate()"
                    @paste="validate()"
                    v-model="item.point_has_condition"
                    v-click-outside="checkShortUrl"
                  />
                </div>
                <label class="font-weight-bold cus-label-p2"> ポイント </label>
                <b-icon
                  icon="gear-fill"
                  class="my-auto ml-2 bt-setting"
                  font-scale="2"
                  style="border-color: #ced2d8; padding: 4px"
                  v-on:click="setCondition(item.conditions, index)"
                />
                <b-button
                  v-if="
                    settingUrl.st_before_redirect.st_give[typeGiveSelect - 1]
                      .st_has_condtion.length > 1
                  "
                  variant="dark"
                  class="rounded-circle custome-btn-sub float-right mt-0 bt-sub"
                  style="border-radius: 50% !important"
                  v-on:click="
                    subSetting(
                      settingUrl.st_before_redirect.st_give[typeGiveSelect - 1]
                        .st_has_condtion,
                      index
                    )
                  "
                  ><b-icon icon="dash" scale="2"
                /></b-button>
              </div>
              <div class="d-flex col-12 ml-5">
                <b-button
                  variant="success"
                  class="rounded-circle custome-btn-add cus-bt-color"
                  style="border-radius: 50% !important"
                  v-on:click="
                    addSetting(
                      settingUrl.st_before_redirect.st_give[typeGiveSelect - 1]
                        .st_has_condtion,
                      index,
                      'point'
                    )
                  "
                  ><b-icon class="sidebar-icon" icon="plus" font-scale="2"
                /></b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center cus-save">
          <b-button
            v-on:click.prevent="save()"
            :disabled="loadingSave"
            class="mr-4 btn-color sale-btn"
          >
            <b-spinner v-if="loadingSave" small></b-spinner>
            <span v-if="!isEdit">登録</span>
            <span v-else>更新する</span>
          </b-button>
          <b-button class="mr-4 back-btn" v-on:click="cancel()">
            戻る
          </b-button>
        </div>
      </form>
    </div>
    <b-modal
      v-model="showPopupSetCondition"
      ref="setting-condition"
      size="lg"
      title="ユーザーが所持する情報を設定"
      hide-footer
    >
      <div
        class="ml-5 mt-2"
        v-for="(val, index) in conditions"
        v-bind:key="index"
      >
        <div class="d-flex">
          <div>
            <b-form-radio-group
              v-model="val.typeConditon"
              :options="typeConditons"
              :name="'plain' + index"
              @change="changeTypeCondition(val)"
              plain
              stacked
            ></b-form-radio-group>
          </div>
          <div class="ml-4 mr-4" style="width: 60%">
            <div
              class="custom-select-2 w-100 custome-multi-slag m-0"
              v-if="val.typeConditon == 1"
            >
              <multiselect
                v-model="val.id"
                tag-placeholder="コンテンツ追加"
                placeholder="コンテンツ追加"
                label="text"
                track-by="value"
                :options="listContentOptions"
                :multiple="true"
                :taggable="false"
                :showNoResults="false"
                select-label="入力して選択する"
                deselect-label="入力して削除する"
                selected-label="選択された"
              >
                <template v-slot:no-options> リストは空です </template>
              </multiselect>
            </div>
            <div class="custom-select-2 w-100 custome-multi-slag m-0" v-else>
              <multiselect
                v-model="val.id"
                tag-placeholder="スラグ追加"
                placeholder="スラグ追加"
                label="text"
                track-by="value"
                :options="listSlagOptions"
                :multiple="true"
                :taggable="false"
                :showNoResults="false"
                :select-label="'入力して選択する'"
                :deselect-label="'入力して削除する'"
                :selected-label="'選択された'"
              >
                <template v-slot:no-options> リストは空です </template>
              </multiselect>
            </div>
          </div>
          <div>
            <b-form-radio-group
              v-model="val.ownershipCondition"
              :options="ownershipConditions"
              :name="'plain1' + index"
              plain
              stacked
            ></b-form-radio-group>
          </div>
          <b-button
            v-if="conditions.length > 1"
            variant="dark"
            class="rounded-circle custome-btn-sub float-right mt-0 ml-4 bt-sub"
            style="border-radius: 50% !important"
            v-on:click="subCondition(index)"
            ><b-icon icon="dash" scale="2"
          /></b-button>
        </div>
        <div class="mt-3">
          <b-button class="mr-4 add-btn" v-on:click="addCondition(index)">
            and設定追加
          </b-button>
        </div>
      </div>

      <div class="d-flex mt-5 justify-content-center">
        <b-button @click="saveCondition" class="mr-4 btn-color sale-btn">
          <span>設定</span>
        </b-button>
        <b-button
          class="mr-4 back-btn"
          v-on:click="showPopupSetCondition = false"
        >
          閉じる
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import { FeedBack } from "@/utils/feedback.js";
import { Constants } from "../../utils/constants.js";
import vClickOutside from "v-click-outside";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});

export default {
  name: "CreateURL",
  components: {},
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      feedback: FeedBack,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      listSlag: [],
      listAllContents: [],
      listSlagOptions: [],
      listContentOptions: [],
      // listSlagGrantOptions: [],
      settingUrl: {
        short_url: "",
        st_url_redirect: {
          url_no_condtion: "",
          st_has_condtion: [
            {
              url_has_condition: "",
              conditions: [
                {
                  typeConditon: 1,
                  id: [],
                  ownershipCondition: 1,
                },
              ],
            },
          ],
        },
        st_redirect_by_time: {
          time_start: "",
          time_end: "",
          url_no_condtion: "",
          st_has_condtion: [
            {
              url_has_condition: "",
              conditions: [
                {
                  typeConditon: 1,
                  id: [],
                  ownershipCondition: 1,
                },
              ],
            },
          ],
        },
        st_before_redirect: {
          type_process: 1,
          st_give: [
            {
              type_give: 1, // content
              content_no_condition: [],
              st_has_condtion: [
                {
                  content_has_condition: [],
                  conditions: [
                    {
                      typeConditon: 1,
                      id: [],
                      ownershipCondition: 1,
                    },
                  ],
                },
              ],
            },
            {
              type_give: 2, // slug
              slag_no_condition: [],
              st_has_condtion: [
                {
                  slag_has_condition: [],
                  conditions: [
                    {
                      typeConditon: 1,
                      id: [],
                      ownershipCondition: 1,
                    },
                  ],
                },
              ],
            },
            {
              type_give: 3, // point
              point_no_condition: null,
              st_has_condtion: [
                {
                  point_has_condition: null,
                  conditions: [
                    {
                      typeConditon: 1,
                      id: [],
                      ownershipCondition: 1,
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      st_give_old: null,
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      listSetting: [
        { value: 1, text: "基本設定" },
        { value: 2, text: "リダイレクト" },
        { value: 3, text: "期間設定" },
        { value: 4, text: "詳細設定" },
      ],
      settingSelect: 1,
      listProcess: [
        { value: 1, text: "すぐリダイレクト" },
        { value: 2, text: "ログイン画面のみ" },
        { value: 3, text: "新規登録画面のみ" },
        { value: 4, text: "ログイン＋新規登録画面" },
      ],
      processSelect: 1,
      listTypeGive: [
        { value: 1, text: "コンテンツ付与" },
        { value: 2, text: "スラグ付与" },
        { value: 3, text: "ポイント付与" },
      ],
      typeGiveSelect: 1,
      baseUrl:
        location.protocol +
        "//" +
        location.hostname +
        (location.port ? ":" + location.port : "") +
        "/",
      typeConditons: [
        { text: "コンテンツ", value: 1 },
        { text: "スラグ", value: 2 },
      ],
      ownershipConditions: [
        { text: "所持", value: 1 },
        { text: "未所持", value: 2 },
      ],
      conditions: [
        {
          typeConditon: 1,
          id: [],
          ownershipCondition: 1,
        },
      ],
      isEdit: false,
      loadingSave: false,
      indexConditionEdit: null,
      showPopupSetCondition: false,
    };
  },
  async created() {
    const request = {
      shop_id: this.shop_id,
    };
    let responseSlag = await this.getALLlistSlag(request);
    this.listSlag = responseSlag.success ? responseSlag.data : [];
    if (this.listSlag.length > 0) {
      this.listSlagOptions = this.listSlag.map((item) => ({
        text: item.slag_name ? item.slag_name : item.slag_name_en,
        value: item.id,
      }));
    } else {
      this.listSlagOptions = [];
    }

    const requestContent = {
      data: {
        shop_id: this.shop_id,
      },
    };
    let responseContent = await this.getAllContents(requestContent);
    this.listAllContents = responseContent.success ? responseContent.data : [];
    if (this.listAllContents.length > 0) {
      this.listContentOptions = this.listAllContents.map((item) => ({
        text: item.title,
        value: item.id,
      }));
    } else {
      this.listContentOptions = [];
    }

    const reqData = {
      id: this.$route.params.id,
      shop_id: this.shop_id,
    };
    if (reqData.id) {
      this.isEdit = true;
      this.getUrlById(reqData);
    }
  },
  computed: {
    ...mapGetters(["detailUrl", "success", "message", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["error", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    detailUrl() {
      if (this.detailUrl) {
        let slagObj = {};
        this.listSlag.forEach((item) => {
          slagObj[item.id] = item.slag_name
            ? item.slag_name
            : item.slag_name_en;
        });

        let contentObj = {};
        this.listAllContents.forEach((item) => {
          contentObj[item.id] = item.title;
        });
        if (
          this.detailUrl.short_url &&
          this.detailUrl.short_url.startsWith("/")
        ) {
          this.settingUrl.short_url = this.detailUrl.short_url.substring(1);
        } else {
          this.settingUrl.short_url = this.detailUrl.short_url;
        }
        if (this.detailUrl.st_url_redirect) {
          this.settingUrl.st_url_redirect = JSON.parse(
            this.detailUrl.st_url_redirect
          );
        }

        if (this.detailUrl.st_before_redirect) {
          this.settingUrl.st_before_redirect = JSON.parse(
            this.detailUrl.st_before_redirect
          );
        }

        if (this.detailUrl.st_redirect_by_time) {
          this.settingUrl.st_redirect_by_time = JSON.parse(
            this.detailUrl.st_redirect_by_time
          );
        }
        // convert name slag, content
        if (
          this.settingUrl.st_url_redirect.st_has_condtion &&
          this.settingUrl.st_url_redirect.st_has_condtion.length > 0
        ) {
          this.settingUrl.st_url_redirect.st_has_condtion.forEach((item) => {
            if (item.conditions && item.conditions.length > 0) {
              item.conditions.forEach((condition) => {
                const obj = condition.typeConditon == 1 ? contentObj : slagObj;
                condition.id.forEach((id) => {
                  id.text = obj[id.value];
                });
              });
            }
          });
        }

        if (
          this.settingUrl.st_redirect_by_time.st_has_condtion &&
          this.settingUrl.st_redirect_by_time.st_has_condtion.length > 0
        ) {
          this.settingUrl.st_redirect_by_time.st_has_condtion.forEach(
            (item) => {
              if (item.conditions && item.conditions.length > 0) {
                item.conditions.forEach((condition) => {
                  const obj =
                    condition.typeConditon == 1 ? contentObj : slagObj;
                  condition.id.forEach((id) => {
                    id.text = obj[id.value];
                  });
                });
              }
            }
          );
        }

        if (this.settingUrl.st_before_redirect) {
          this.settingUrl.st_before_redirect.st_give.forEach((item, index) => {
            if (index == 0) {
              item.content_no_condition.forEach((content) => {
                content.text = contentObj[content.value];
              });
              item.st_has_condtion.forEach((val) => {
                val.content_has_condition.forEach((content) => {
                  content.text = contentObj[content.value];
                });
                val.conditions.forEach((condition) => {
                  const obj =
                    condition.typeConditon == 1 ? contentObj : slagObj;
                  condition.id.forEach((id) => {
                    id.text = obj[id.value];
                  });
                });
              });
            }
            if (index == 1) {
              item.slag_no_condition.forEach((slag) => {
                slag.text = slagObj[slag.value];
              });
              item.st_has_condtion.forEach((val) => {
                val.slag_has_condition.forEach((slag) => {
                  slag.text = slagObj[slag.value];
                });
                val.conditions.forEach((condition) => {
                  const obj =
                    condition.typeConditon == 1 ? contentObj : slagObj;
                  condition.id.forEach((id) => {
                    id.text = obj[id.value];
                  });
                });
              });
            }
            if (index == 2) {
              item.st_has_condtion.forEach((val) => {
                val.conditions.forEach((condition) => {
                  const obj =
                    condition.typeConditon == 1 ? contentObj : slagObj;
                  condition.id.forEach((id) => {
                    id.text = obj[id.value];
                  });
                });
              });
            }
          });
          this.processSelect = this.settingUrl.st_before_redirect.type_process;
        }

        // save setting old
        // if (
        //   this.settingUrl.st_before_redirect &&
        //   this.settingUrl.st_before_redirect.st_give
        // ) {
        //   this.st_give_old = JSON.parse(
        //     JSON.stringify(this.settingUrl.st_before_redirect.st_give)
        //   );
        // }
      }
    },
    // listSlag() {
    //   if (this.listSlag.length > 0) {
    //     this.listSlagOptions = this.listSlag.map((item) => ({
    //       text: item.slag_name ? item.slag_name : item.slag_name_en,
    //       value: item.id,
    //     }));
    //   } else {
    //     this.listSlagOptions = [];
    //   }
    // },
    // listAllContents() {
    //   if (this.listAllContents.length > 0) {
    //     this.listContentOptions = this.listAllContents.map((item) => ({
    //       text: item.title,
    //       value: item.id,
    //     }));
    //   } else {
    //     this.listContentOptions = [];
    //   }
    // },
  },
  methods: {
    ...mapActions({ getALLlistSlag: "getALLlistSlag" }),
    ...mapActions({ getAllContents: "getAllContents" }),
    ...mapActions({ getUrlById: "getUrlById" }),
    ...mapActions({ UpdateURL: "UpdateURL" }),
    ...mapActions({ CreateURL: "CreateURL" }),
    cancel() {
      this.$router.push({
        name: this.$route.params.shopId ? "URLManager" : "URLManager domain",
        params: { shopId: this.$route.params.shopId },
      });
    },
    checkShortUrl() {
      if (
        this.settingUrl.short_url_show &&
        this.settingUrl.short_url_show.startsWith("/")
      ) {
        this.settingUrl.short_url_show =
          this.settingUrl.short_url_show.substring(1);
      }
    },
    checkFormatUrl(url) {
      if (url && !url.match(Constants.REGEX_URL)) {
        this.$toasted.error("リダイレクトURL設定が正しい形式ではありません");
      }
    },
    async save() {
      if (!this.settingUrl.short_url) {
        this.$toasted.error("URLを設計するを入力してください。");
        return;
      }
      if (
        this.settingUrl.st_redirect_by_time.time_start &&
        this.settingUrl.st_redirect_by_time.time_end &&
        this.settingUrl.st_redirect_by_time.time_start >
          this.settingUrl.st_redirect_by_time.time_end
      ) {
        this.$toasted.error("終了日には、開始日より後の日付を指定してください");
        return;
      }
      this.loadingSave = true;
      // add "/" before short url
      if (
        this.settingUrl.short_url &&
        this.settingUrl.short_url.startsWith("/")
      ) {
        this.settingUrl.short_url = this.settingUrl.short_url.substring(1);
      }
      this.settingUrl.st_before_redirect.type_process = this.processSelect;

      this.settingUrl.st_url_redirect.st_has_condtion =
        this.settingUrl.st_url_redirect.st_has_condtion.filter((val, index) => {
          if (index == 0 || val.url_has_condition) return val;
        });

      this.settingUrl.st_redirect_by_time.st_has_condtion =
        this.settingUrl.st_redirect_by_time.st_has_condtion.filter(
          (val, index) => {
            if (index == 0 || val.url_has_condition) return val;
          }
        );

      this.settingUrl.st_before_redirect.st_give[0].st_has_condtion =
        this.settingUrl.st_before_redirect.st_give[0].st_has_condtion.filter(
          (val, index) => {
            if (index == 0 || val.content_has_condition.length > 0) return val;
          }
        );

      this.settingUrl.st_before_redirect.st_give[1].st_has_condtion =
        this.settingUrl.st_before_redirect.st_give[1].st_has_condtion.filter(
          (val, index) => {
            if (index == 0 || val.slag_has_condition.length > 0) return val;
          }
        );

      this.settingUrl.st_before_redirect.st_give[2].st_has_condtion =
        this.settingUrl.st_before_redirect.st_give[2].st_has_condtion.filter(
          (val, index) => {
            if (index == 0 || val.point_has_condition > 0) return val;
          }
        );

      const request = {
        shop_id: this.shop_id,
        id: this.$route.params.id ? this.$route.params.id : "",
        short_url: this.settingUrl.short_url,
        st_redirect_by_time: this.settingUrl.st_redirect_by_time,
        st_before_redirect: this.settingUrl.st_before_redirect,
        st_url_redirect: this.settingUrl.st_url_redirect,
      };
      if (request.id) {
        const dataReturn = await this.$store.dispatch("UpdateURL", request);
        this.loadingSave = false;
        if (dataReturn.success) {
          this.$router.push({
            name: this.$route.params.shopId
              ? "URLManager"
              : "URLManager domain",
            params: { shopId: this.$route.params.shopId },
          });
          localStorage.removeItem(Constants.NO_URL);
        }
      } else {
        const dataReturn = await this.$store.dispatch("CreateURL", request);
        this.loadingSave = false;
        if (dataReturn.success) {
          this.$router.push({
            name: this.$route.params.shopId
              ? "URLManager"
              : "URLManager domain",
            params: { shopId: this.$route.params.shopId },
          });
          localStorage.removeItem(Constants.NO_URL);
        }
      }
    },
    addSetting(item, index, type) {
      let obj = {
        conditions: [
          {
            typeConditon: 1,
            id: [],
            ownershipCondition: 1,
          },
        ],
      };
      if (type == "url") {
        obj["url_has_condition"] = "";
      }
      if (type == "content") {
        obj["content_has_condition"] = [];
      }
      if (type == "slag") {
        obj["slag_has_condition"] = [];
      }

      item.splice(index + 1, 0, obj);
    },
    subSetting(item, index) {
      item.splice(index, 1);
    },
    addCondition(index) {
      this.conditions.splice(index + 1, 0, {
        typeConditon: 1,
        id: [],
        ownershipCondition: 1,
      });
    },
    subCondition(index) {
      this.conditions.splice(index, 1);
    },
    setCondition(item, index) {
      this.conditions = item.map((val) => {
        return JSON.parse(JSON.stringify(val));
      });
      this.showPopupSetCondition = true;
      this.indexConditionEdit = index;
    },
    changeTypeCondition(condition) {
      condition.id = [];
    },
    saveCondition() {
      if (this.settingSelect == 2) {
        this.settingUrl.st_url_redirect.st_has_condtion[
          this.indexConditionEdit
        ].conditions = this.conditions.filter((val) => {
          if (val.id.length > 0) return JSON.parse(JSON.stringify(val));
        });
      }
      if (this.settingSelect == 3) {
        this.settingUrl.st_redirect_by_time.st_has_condtion[
          this.indexConditionEdit
        ].conditions = this.conditions.filter((val) => {
          if (val.id.length > 0) return JSON.parse(JSON.stringify(val));
        });
      }
      if (this.settingSelect == 4) {
        this.settingUrl.st_before_redirect.st_give[
          this.typeGiveSelect - 1
        ].st_has_condtion[this.indexConditionEdit].conditions =
          this.conditions.filter((val) => {
            if (val.id.length > 0) return JSON.parse(JSON.stringify(val));
          });
      }
      this.showPopupSetCondition = false;
    },
    changProcess(value) {
      this.processSelect = value;
    },
    validate(evt) {
      var theEvent = evt || window.event;

      // Handle paste
      if (theEvent.type === "paste") {
        key = theEvent.clipboardData.getData("text/plain");
      } else {
        // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
      }
      var regex = /[0-9]|\./;
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    },
  },
};
</script>
<style lang="scss">
.ql-container {
  border: none !important;
  .ql-editor {
    background-color: white;
    height: auto;
  }
}
.error-input {
  border: solid 1px #e55353 !important;
}
.message-error {
  color: #e55353;
}
.custome-width-50 {
  width: 50%;
}
.btn-tab-sale-active,
.btn-tab-sale-inactive {
  @media (max-width: 767px) {
    width: 80px;
  }
  @media (min-width: 1280px) {
    width: 130px;
  }
}
.btn-tab-sale-active {
  background: #ff644e;
  border: #ff644e;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 7px;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  min-width: 100px;
}
.btn-tab-sale-inactive {
  color: #636f83;
  border-color: #636f83;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #636f83;
  padding: 7px;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  min-width: 100px;
}
.btn-tab-sale-active:after {
  position: absolute;
  bottom: -9px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 9px solid #ff644e;
}
.sale-btn {
  width: 120px !important;
  height: 42px !important;
  background-color: #00b797 !important;
  color: #fff !important;
  border-radius: 6px !important;
  font-size: 20px;
}
.back-btn {
  width: 120px !important;
  height: 42px !important;
  color: black !important;
  border-radius: 6px !important;
  font-size: 20px;
}
.sale-btn:hover {
  width: 120px !important;
  height: 42px !important;
  background-color: #00ab82 !important;
  color: #fff !important;
  border-radius: 6px !important;
}
@media (min-width: 1024px) {
  .header-font {
    position: relative;
    bottom: -5px;
  }
}
.add-btn {
  background-color: #3838aa;
  color: white;
}
.bt-sub {
  width: 32px;
  height: 32px;
  margin-top: 3px !important;
  margin-left: 10px;
}
.bt-setting {
  margin-top: 0px !important;
  width: 40px;
  height: 40px;
}
.btn-secondary:focus {
  background-color: #3838aa;
  color: white;
  box-shadow: unset;
}
.btn-select-active {
  background: #ff644e;
  border: #ff644e;
  color: #fff;
  border-radius: 3px;
  padding: 7px;
}
.btn-select-inactive {
  background: #01b796;
  border: #01b796;
  color: #fff;
  border-radius: 3px;
  padding: 7px;
}
.cus-save {
  margin-top: 60px;
}
.cus-label-c {
  margin-top: 12px;
  width: 140px;
}
.cus-label-p {
  margin-top: 7px;
  width: 80px;
}
.cus-label-p2 {
  margin-top: 7px;
  width: 60px;
}
.cus-bt-color {
  background-color: #337bc0 !important;
  border-color: #337bc0 !important;
}
</style>
